
import { Instructors } from "@/api";
import { Gender, Instructor, Service } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { toSnake } from "snake-camel";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { PageTitle, ValidationProvider, ValidationObserver, PhoneInput },
  mixins: [PhoneCodes]
})
export default class EditInstructorsPage extends Vue {
  menuVacationStart = false;
  menuVacationEnd = false;
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];
  headersTitle = [
    { icon: "mdi-account-box", header: "Instructor Profile Editor" }
  ];
  instructor: Instructor = {
    id: null,
    name: "",
    email: "",
    phone: "",
    genderId: "",
    workingHourFrom: "",
    workingHourTo: "",
    serviceId: null,
    outsourceCost: "",
    vacantionFrom: "",
    vacantionTo: ""
  };
  servicesAndGenders: { genders: Array<Gender>; services: Array<Service> } = {
    services: [],
    genders: []
  };
  vacationStart = moment().format("YYYY-MM-DD");
  vacationEnd = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  get outsourceRules() {
    return this.isPermanent ? "" : "required";
  }
  get vacationStartDateFormatted() {
    return moment(this.vacationStart).format("DD/MM/YYYY");
  }
  get vacationEndDateFormatted() {
    return moment(this.vacationEnd).format("DD/MM/YYYY");
  }
  get isPermanent() {
    return this.instructor.serviceId === 1 || !this.instructor.serviceId;
  }
  get gender() {
    return this.servicesAndGenders.genders.find(
      (item: Gender) => item.id === this.instructor.genderId
    )?.name;
  }
  get genders() {
    return this.servicesAndGenders.genders.map((item: Gender) => item.name);
  }
  async created() {
    try {
      this.servicesAndGenders = await Instructors.servicesAdnGenders();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async onGoBack() {
    await this.$router.push({ name: "Instructors" });
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async save() {
    try {
      const data = JSON.parse(JSON.stringify(this.instructor));
      data.vacantionFrom = moment(this.vacationStart).format("DD-MM-YYYY");
      data.vacantionTo = moment(this.vacationEnd).format("DD-MM-YYYY");
      if (this.isPermanent) {
        delete data.outsourceCost;
      }
      const reqData = toSnake(data);
      await Instructors.create(reqData);
      await this.$success(
        "<strong>Success!</strong> Instructor has been created!"
      );

      if (this.$route.name === "CreateInstructor") {
        await this.$router.push({ name: "Instructors" });
      }
    } catch (e) {
      const err = e as any;
      (this.$refs.instructorForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }
  requredRule(v: any) {
    return !!v || "Field in require";
  }
}
